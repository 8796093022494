import React from 'react';

export default function FaqAccordionTitle2() {
    return (
        <div >
            <div className="langCng" lang="en">
                Q2 I don't know how to use it, what should I do?
            </div>

            <div className="langCng" lang="es">
                Q2 I don't know how to use it, what should I do?
            </div>

            <div className="langCng" lang="ru">
                Q2 Я не знаю, как им пользоваться, что мне делать?
            </div>

            <div className="langCng" lang="fr">
                Q2 Qui est responsable si une tricherie est constatée ?
            </div>

            <div className="langCng" lang="de">
                Q2 Ich weiß nicht, wie ich es benutzen soll, was soll ich tun?
            </div>

            <div className="langCng" lang="it">
                Q2 Non so come usarlo, cosa devo fare?
            </div>

            <div className="langCng" lang="hi">
                Q2 मुझे नहीं पता कि इसका उपयोग कैसे करना है, मुझे क्या करना चाहिए?
            </div>

            <div className="langCng" lang="id">
                Q2 Saya tidak tahu cara menggunakannya, apa yang harus saya lakukan?
            </div>

            <div className="langCng" lang="pt">
                Q2 Não sei usar, o que devo fazer?
            </div>

            <div className="langCng" lang="fi">
                Q2 En tiedä kuinka käyttää sitä, mitä minun pitäisi tehdä?
            </div>

            <div className="langCng" lang="tr">
                Q2 Nasıl kullanacağımı bilmiyorum, ne yapmalıyım?
            </div>          

            <div className="langCng" lang="ms">
                Q2 Saya tidak tahu bagaimana untuk menggunakannya, apa yang perlu saya lakukan?
            </div>

            <div className="langCng" lang="ta">
                Q2 அதை எப்படி பயன்படுத்துவது என்று எனக்குத் தெரியவில்லை, நான் என்ன செய்ய வேண்டும்?
            </div>     

            <div className="langCng" lang="ar">
                س 2 لا أعرف كيف أستخدمه ، فماذا أفعل؟
            </div>

            <div className="langCng" lang="ja">
                Q2 使い方がわからないどうすればいいですか？
            </div>

            <div className="langCng" lang="zh">
                Q2 我不知道如何使用它，我该怎么办？
            </div>

            <div className="langCng" lang="ko">
                Q2 사용법을 모르는 방법은 무엇입니까?
            </div>
        </div>
    );
  }