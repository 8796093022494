import React from 'react';


export default function FaqAccordionTitle1() {
    return (
        <div >
            <div className="langCng" lang="en">
            Q1 Who is responsible if cheating is noticed?
            </div>

            <div className="langCng" lang="es">
                Q1 ¿Quién es responsable si se detecta una trampa?
            </div>

            <div className="langCng" lang="ru">
                Q1 Кто будет нести ответственность, если обман будет замечен?
            </div>

            <div className="langCng" lang="fr">
                Q1 Je ne sais pas comment l'utiliser, que dois-je faire ?
            </div>

            <div className="langCng" lang="de">
                Q1 Wer haftet, wenn Betrug bemerkt wird?
            </div>

            <div className="langCng" lang="it">
                Q1 Chi è responsabile se si nota un imbroglio?
            </div>

            <div className="langCng" lang="hi">
                Q1 यदि धोखाधड़ी का पता चलता है तो कौन जिम्मेदार है?
            </div>

            <div className="langCng" lang="id">
                Q1 Siapa yang bertanggung jawab jika kecurangan diketahui?
            </div>

            <div className="langCng" lang="pt">
                Q1 Quem é responsável se a trapaça for percebida?
            </div>

            <div className="langCng" lang="fi">
                Q1 Kuka on vastuussa, jos huijaus huomataan?
            </div>

            <div className="langCng" lang="tr">
                Q1 Hile fark edilirse sorumlu kim?
            </div>          

            <div className="langCng" lang="ms">
                Q1 Siapa yang bertanggungjawab jika berlaku penipuan?
            </div>

            <div className="langCng" lang="ta">
                Q1 ஏமாற்றுவது தெரிந்தால் யார் பொறுப்பு?
            </div>     

            <div className="langCng" lang="ar">
            س 1 من المسؤول إذا لوحظ الغش؟
            </div>

            <div className="langCng" lang="ja">
                Q1 不正行為に気付かれた場合、誰に責任があるのか？
            </div>

            <div className="langCng" lang="zh">
                Q1 如果发现作弊行为谁来负责？
            </div>

            <div className="langCng" lang="ko">
                Q1 부정 행위를 알게 되면 누구에게 책임이 있는가?
            </div>
        </div>
    );
  }