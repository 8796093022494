import React from 'react';

export default function FaqAccordionTitle1() {
    return (
        <div >
            <div className="langCng" lang="en">
                As stated in the terms, it is the user's responsibility.
            </div>

            <div className="langCng" lang="es">
                Como se indica en los términos, es responsabilidad del usuario.
            </div>

            <div className="langCng" lang="ru">
                Как указано в условиях, ответственность за это лежит на пользователе.
            </div>

            <div className="langCng" lang="fr">
                Comme indiqué dans les termes, c'est la responsabilité de l'utilisateur.
            </div>

            <div className="langCng" lang="de">
                Wie in den Bedingungen angegeben, liegt dies in der Verantwortung des Benutzers.
            </div>

            <div className="langCng" lang="it">
                Come indicato nei termini, è responsabilità dell'utente.
            </div>

            <div className="langCng" lang="hi">
                जैसा कि शर्तों में कहा गया है, यह उपयोगकर्ता की ज़िम्मेदारी है।
            </div>

            <div className="langCng" lang="id">
                Sebagaimana dinyatakan dalam persyaratan, itu adalah tanggung jawab pengguna.
            </div>

            <div className="langCng" lang="pt">
                Conforme declarado nos termos, é de responsabilidade do usuário.
            </div>

            <div className="langCng" lang="fi">
                Kuten ehdoissa sanotaan, se on käyttäjän vastuulla.
            </div>

            <div className="langCng" lang="tr">
                Şartlarda belirtildiği gibi, kullanıcının sorumluluğundadır.
            </div>          

            <div className="langCng" lang="ms">
                Seperti yang dinyatakan dalam terma, ia adalah tanggungjawab pengguna.
            </div>

            <div className="langCng" lang="ta">
                விதிமுறைகளில் கூறப்பட்டுள்ளபடி, இது பயனரின் பொறுப்பாகும்.
            </div>     

            <div className="langCng" lang="ar">
                كما هو مذكور في الشروط ، فهي مسؤولية المستخدم.
            </div>

            <div className="langCng" lang="ja">
                利用規約の通り、ユーザーの責任です。
            </div>

            <div className="langCng" lang="zh">
                根据使用条款，这是用户的责任。
            </div>

            <div className="langCng" lang="ko">
                이용약관대로 사용자의 책임입니다.
            </div>
        </div>
    );
  }